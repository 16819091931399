import React from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { useSiteLinksSearchBoxJsonLd } from 'src/views/home/Seo/useSiteLinksSearchBoxJsonLd'
import type { PageProps } from 'gatsby'
import type { QuizPeleQueryQuery } from 'src/pages/quiz-pele/__generated__/QuizPeleQuery.graphql'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

type Props = PageProps<QuizPeleQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata =
    props?.data?.cmsInstitutionalPage?.seo?.siteMetadataWithSlug

  const canonicalPathname = `https://www.avon.com.br/${siteMetadata?.slug}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')

  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)

  const { isRepresentativeSelected } = useRepresentative()

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalPathname} />
      </Helmet>
      <GatsbySeo
        title={siteMetadata?.title ?? 'Ritual de Beleza'}
        description={siteMetadata?.description ?? 'Ritual de Beleza'}
        titleTemplate={siteMetadata?.titleTemplate ?? '%s | Avon'}
        noindex={!!isRepresentativeSelected}
      />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo
