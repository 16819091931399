import React, { lazy } from 'react'
import { Box, SuspenseSSR } from '@vtex/store-ui'
import QuizFirstQuestion from 'src/components/quiz-pele/Questions/QuizFirstQuestion'

const QuizSecondQuestion = lazy(
  () => import('src/components/quiz-pele/Questions/QuizSecondQuestion')
)

const QuizThirdQuestion = lazy(
  () => import('src/components/quiz-pele/Questions/QuizThirdQuestion')
)

const QuizFourthQuestion = lazy(
  () => import('src/components/quiz-pele/Questions/QuizFourthQuestion')
)

const QuizFifthQuestion = lazy(
  () => import('src/components/quiz-pele/Questions/QuizFifthQuestion')
)

const QuizSixthQuestion = lazy(
  () => import('src/components/quiz-pele/Questions/QuizSixthQuestion')
)

const QuizSeventhQuestion = lazy(
  () => import('src/components/quiz-pele/Questions/QuizSeventhQuestion')
)

const Questions = ({ step, data }) => {
  return (
    <Box>
      {step === 1 && <QuizFirstQuestion data={data} />}
      <SuspenseSSR fallback={null}>
        {step === 2 && <QuizSecondQuestion data={data} />}
        {step === 3 && <QuizThirdQuestion data={data} />}
        {step === 4 && <QuizFourthQuestion data={data} />}
        {step === 5 && <QuizFifthQuestion data={data} />}
        {step === 6 && <QuizSixthQuestion data={data} />}
        {step === 7 && <QuizSeventhQuestion data={data} />}
      </SuspenseSSR>
    </Box>
  )
}

export default Questions
