import React, { useContext } from 'react'
import { Box, Flex, Image, Input, Label, Text } from '@vtex/store-ui'
import useDeviceDetect from 'src/hooks/useDeviceDetect'
import { QuizPeleContext } from 'src/views/quiz-pele/context'

import styles from '../styles.json'

const QuizFirstQuestion = ({ data }) => {
  const { isMobile } = useDeviceDetect()
  const image = data.cmsInstitutionalPage.sections[0].props
  const options = [
    {
      position: 0,
      label: 'Não faço nada',
      id: 'nenhumaExperiencia',
      name: 'cuidadoPele',
      type: 'radio',
      value: 1,
      complement: 'Tratamento Diário|Área dos Olhos',
      scenario: '1|2|5',
    },
    {
      position: 0,
      label: 'Limpo e Protejo',
      id: 'limpoProtejo',
      name: 'cuidadoPele',
      type: 'radio',
      value: 2,
      complement: 'Tratamentos Específicos',
      scenario: '1|3|4|5',
    },
    {
      position: 0,
      label: 'Limpo, cuido do rosto e aréa dos olhos',
      id: 'cuidoRosto',
      name: 'cuidadoPele',
      type: 'radio',
      value: 3,
      complement: '',
      scenario: '1|2|3|4|5',
    },
    {
      position: 0,
      label: 'Limpo, Trato e cuido do rosto e área dos olhos',
      id: 'cuidoLimpoOlhos',
      name: 'cuidadoPele',
      type: 'radio',
      value: 4,
      complement: '',
      scenario: '1|2|3|4|5',
    },
  ]

  const { setAnswers, nextQuestion } = useContext(QuizPeleContext)

  const handleClick = ({ target }) => {
    const firstQuestion = {
      firsQuestionValue: target.value,
      firstQuestionScenario: target.getAttribute('scenario'),
      firstQuestionComplement: `${target.getAttribute('complement')}`,
    }

    setAnswers((state) => ({ ...state, firstQuestion }))

    nextQuestion()
  }

  return (
    <Box sx={styles.container}>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.imageContainer}>
          <Image
            src={isMobile ? image.mobile.srcSet : image.desktop.srcSet}
            alt={image.alt}
            title={image.title}
          />
        </Box>
        <Box sx={styles.questionContainer}>
          <Box sx={styles.questionContainer.title}>
            <Text as="h2">
              Vamos criar um ritual de skincare personalizado para você, topa?
              Primeiro me conta como você cuida da pele atualmente:
            </Text>
          </Box>
          {options.map((option: any, index: number) => (
            <Box key={index} sx={styles.questionContainer.question}>
              <Flex>
                <Input
                  type={option.type}
                  name={option.name}
                  id={option.id}
                  value={option.value}
                  scenario={option.scenario}
                  complement={option.complement}
                  onChange={(event: any) => handleClick(event)}
                />
                <Label htmlFor={option.id}>{option.label}</Label>
              </Flex>
            </Box>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

export default QuizFirstQuestion
