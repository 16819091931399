import React, { useContext } from 'react'
import { Box, SuspenseSSR } from '@vtex/store-ui'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import Header from 'src/components/quiz-pele/Header'
import Questions from 'src/components/quiz-pele/Questions'

import { QuizPeleContext } from './context'

const QuizPele = ({ data }) => {
  const { setIsLoginModalOpen } = useLoginModal()
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'
  const { step } = useContext(QuizPeleContext)

  if (!isAuthenticated) {
    setIsLoginModalOpen(true)
  }

  return (
    <Box>
      <Header />
      <Box
        as="form"
        sx={{
          height: '88vh',
          overflow: ['auto', 'hidden'],
        }}
      >
        <SuspenseSSR fallback={null}>
          <Questions step={step} data={data} />
        </SuspenseSSR>
      </Box>
    </Box>
  )
}

export default QuizPele
