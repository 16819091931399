import React, { createContext, useState } from 'react'
import type { ReactNode } from 'react'
import { navigate } from 'gatsby'

interface IQuizContext {
  step: number
  answers: any
  userNameError: boolean
  userEmailError: boolean
  successForm: boolean
  nextQuestion: () => void
  backQuestion: () => void
  setAnswers: React.Dispatch<React.SetStateAction<string>>
  setUserNameError: React.Dispatch<React.SetStateAction<boolean>>
  setUserEmailError: React.Dispatch<React.SetStateAction<boolean>>
  setSuccessForm: React.Dispatch<React.SetStateAction<boolean>>
}

export const QuizPeleContext = createContext({} as IQuizContext)

export const QuizPeleContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [step, setStep] = useState(1)
  const [answers, setAnswers] = useState()
  const [userNameError, setUserNameError] = useState<boolean>(false)
  const [userEmailError, setUserEmailError] = useState<boolean>(false)
  const [successForm, setSuccessForm] = useState<boolean>(false)

  const nextQuestion = () => {
    return setStep(step + 1)
  }

  const backQuestion = () => {
    if (step > 1) {
      setStep(step - 1)
    }

    if (step === 1) {
      navigate('/')
    }
  }

  return (
    <QuizPeleContext.Provider
      value={{
        step,
        answers,
        userNameError,
        userEmailError,
        successForm,
        nextQuestion,
        backQuestion,
        setAnswers,
        setUserNameError,
        setUserEmailError,
        setSuccessForm,
      }}
    >
      {children}
    </QuizPeleContext.Provider>
  )
}
